import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="Data Protection" />
    <div className="Hero">
      <h1>Data Protection</h1>

      <p>
        Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und Zweck
        der Verarbeitung von personenbezogenen Daten (nachfolgend kurz „Daten“)
        innerhalb unseres Onlineangebotes und der mit ihm verbundenen Webseiten,
        Funktionen und Inhalte sowie externen Onlinepräsenzen, wie z.B. unser
        Social Media Profile auf. (nachfolgend gemeinsam bezeichnet als
        „Onlineangebot“). Im Hinblick auf die verwendeten Begrifflichkeiten, wie
        z.B. „Verarbeitung“ oder „Verantwortlicher“ verweisen wir auf die
        Definitionen im Art. 4 der Datenschutzgrundverordnung (DSGVO).
      </p>
      <p>
        <strong>Verantwortlicher</strong>
      </p>
      <p>
        Micha Wiebe
        <br />
        Itzehoer Weg 3<br />
        20251 Hamburg
      </p>
      <p>
        <strong>Arten der verarbeiteten Daten:</strong>
      </p>
      <p>
        &#8211; Bestandsdaten (z.B., Namen, Adressen).
        <br />
        &#8211; Kontaktdaten (z.B., E-Mail, Telefonnummern).
        <br />
        &#8211; Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos).
        <br />
        &#8211; Nutzungsdaten (z.B., besuchte Webseiten, Interesse an Inhalten,
        Zugriffszeiten).
        <br />
        &#8211; Meta-/Kommunikationsdaten (z.B., Geräte-Informationen,
        IP-Adressen).
      </p>
      <p>
        <strong>Kategorien betroffener Personen</strong>
      </p>
      <p>
        Besucher und Nutzer des Onlineangebotes (Nachfolgend bezeichnen wir die
        betroffenen Personen zusammenfassend auch als „Nutzer“).
      </p>
      <p>
        <strong>Zweck der Verarbeitung</strong>
      </p>
      <p>
        &#8211; Zurverfügungstellung des Onlineangebotes, seiner Funktionen und
        Inhalte.
        <br />
        &#8211; Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern.
        <br />
        &#8211; Sicherheitsmaßnahmen.
        <br />
        &#8211; Reichweitenmessung/Marketing
      </p>
      <p>
        <strong>Verwendete Begrifflichkeiten </strong>
      </p>
      <p>
        „Personenbezogene Daten“ sind alle Informationen, die sich auf eine
        identifizierte oder identifizierbare natürliche Person (im Folgenden
        „betroffene Person“) beziehen; als identifizierbar wird eine natürliche
        Person angesehen, die direkt oder indirekt, insbesondere mittels
        Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu
        Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem oder
        mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck
        der physischen, physiologischen, genetischen, psychischen,
        wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen
        Person sind.
      </p>
      <p>
        „Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter Verfahren
        ausgeführten Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit
        personenbezogenen Daten. Der Begriff reicht weit und umfasst praktisch
        jeden Umgang mit Daten.
      </p>
      <p>
        Als „Verantwortlicher“ wird die natürliche oder juristische Person,
        Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit
        anderen über die Zwecke und Mittel der Verarbeitung von
        personenbezogenen Daten entscheidet, bezeichnet.
      </p>
      <p>
        <strong>Maßgebliche Rechtsgrundlagen</strong>
      </p>
      <p>
        Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen
        unserer Datenverarbeitungen mit. Sofern die Rechtsgrundlage in der
        Datenschutzerklärung nicht genannt wird, gilt Folgendes: Die
        Rechtsgrundlage für die Einholung von Einwilligungen ist Art. 6 Abs. 1
        lit. a und Art. 7 DSGVO, die Rechtsgrundlage für die Verarbeitung zur
        Erfüllung unserer Leistungen und Durchführung vertraglicher Maßnahmen
        sowie Beantwortung von Anfragen ist Art. 6 Abs. 1 lit. b DSGVO, die
        Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer rechtlichen
        Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO, und die Rechtsgrundlage
        für die Verarbeitung zur Wahrung unserer berechtigten Interessen ist
        Art. 6 Abs. 1 lit. f DSGVO. Für den Fall, dass lebenswichtige Interessen
        der betroffenen Person oder einer anderen natürlichen Person eine
        Verarbeitung personenbezogener Daten erforderlich machen, dient Art. 6
        Abs. 1 lit. d DSGVO als Rechtsgrundlage.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Zusammenarbeit mit Auftragsverarbeitern und Dritten</strong>
      </p>
      <p>
        Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber anderen
        Personen und Unternehmen (Auftragsverarbeitern oder Dritten) offenbaren,
        sie an diese übermitteln oder ihnen sonst Zugriff auf die Daten
        gewähren, erfolgt dies nur auf Grundlage einer gesetzlichen Erlaubnis
        (z.B. wenn eine Übermittlung der Daten an Dritte, wie an
        Zahlungsdienstleister, gem. Art. 6 Abs. 1 lit. b DSGVO zur
        Vertragserfüllung erforderlich ist), Sie eingewilligt haben, eine
        rechtliche Verpflichtung dies vorsieht oder auf Grundlage unserer
        berechtigten Interessen (z.B. beim Einsatz von Beauftragten, Webhostern,
        etc.).
      </p>
      <p>
        Sofern wir Dritte mit der Verarbeitung von Daten auf Grundlage eines
        sog. „Auftragsverarbeitungsvertrages“ beauftragen, geschieht dies auf
        Grundlage des Art. 28 DSGVO.
      </p>
      <p>
        <strong>Übermittlungen in Drittländer</strong>
      </p>
      <p>
        Sofern wir Daten in einem Drittland (d.h. außerhalb der Europäischen
        Union (EU) oder des Europäischen Wirtschaftsraums (EWR)) verarbeiten
        oder dies im Rahmen der Inanspruchnahme von Diensten Dritter oder
        Offenlegung, bzw. Übermittlung von Daten an Dritte geschieht, erfolgt
        dies nur, wenn es zur Erfüllung unserer (vor)vertraglichen Pflichten,
        auf Grundlage Ihrer Einwilligung, aufgrund einer rechtlichen
        Verpflichtung oder auf Grundlage unserer berechtigten Interessen
        geschieht. Vorbehaltlich gesetzlicher oder vertraglicher Erlaubnisse,
        verarbeiten oder lassen wir die Daten in einem Drittland nur beim
        Vorliegen der besonderen Voraussetzungen der Art. 44 ff. DSGVO
        verarbeiten. D.h. die Verarbeitung erfolgt z.B. auf Grundlage besonderer
        Garantien, wie der offiziell anerkannten Feststellung eines der EU
        entsprechenden Datenschutzniveaus (z.B. für die USA durch das „Privacy
        Shield“) oder Beachtung offiziell anerkannter spezieller vertraglicher
        Verpflichtungen (so genannte „Standardvertragsklauseln“).
      </p>
      <p>
        <strong>Rechte der betroffenen Personen</strong>
      </p>
      <p>
        Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob
        betreffende Daten verarbeitet werden und auf Auskunft über diese Daten
        sowie auf weitere Informationen und Kopie der Daten entsprechend Art. 15
        DSGVO.
      </p>
      <p>
        Sie haben entsprechend. Art. 16 DSGVO das Recht, die Vervollständigung
        der Sie betreffenden Daten oder die Berichtigung der Sie betreffenden
        unrichtigen Daten zu verlangen.
      </p>
      <p>
        Sie haben nach Maßgabe des Art. 17 DSGVO das Recht zu verlangen, dass
        betreffende Daten unverzüglich gelöscht werden, bzw. alternativ nach
        Maßgabe des Art. 18 DSGVO eine Einschränkung der Verarbeitung der Daten
        zu verlangen.
      </p>
      <p>
        Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten, die
        Sie uns bereitgestellt haben nach Maßgabe des Art. 20 DSGVO zu erhalten
        und deren Übermittlung an andere Verantwortliche zu fordern.
      </p>
      <p>
        Sie haben ferner gem. Art. 77 DSGVO das Recht, eine Beschwerde bei der
        zuständigen Aufsichtsbehörde einzureichen.
      </p>
      <p>
        <strong>Widerrufsrecht</strong>
      </p>
      <p>
        Sie haben das Recht, erteilte Einwilligungen gem. Art. 7 Abs. 3 DSGVO
        mit Wirkung für die Zukunft zu widerrufen
      </p>
      <p>
        <strong>Widerspruchsrecht</strong>
      </p>
      <p>
        Sie können der künftigen Verarbeitung der Sie betreffenden Daten nach
        Maßgabe des Art. 21 DSGVO jederzeit widersprechen. Der Widerspruch kann
        insbesondere gegen die Verarbeitung für Zwecke der Direktwerbung
        erfolgen.
      </p>
      <p>
        <strong>Cookies und Widerspruchsrecht bei Direktwerbung</strong>
      </p>
      <p>
        Als „Cookies“ werden kleine Dateien bezeichnet, die auf Rechnern der
        Nutzer gespeichert werden. Innerhalb der Cookies können unterschiedliche
        Angaben gespeichert werden. Ein Cookie dient primär dazu, die Angaben zu
        einem Nutzer (bzw. dem Gerät auf dem das Cookie gespeichert ist) während
        oder auch nach seinem Besuch innerhalb eines Onlineangebotes zu
        speichern. Als temporäre Cookies, bzw. „Session-Cookies“ oder
        „transiente Cookies“, werden Cookies bezeichnet, die gelöscht werden,
        nachdem ein Nutzer ein Onlineangebot verlässt und seinen Browser
        schließt. In einem solchen Cookie kann z.B. der Inhalt eines Warenkorbs
        in einem Onlineshop oder ein Login-Staus gespeichert werden. Als
        „permanent“ oder „persistent“ werden Cookies bezeichnet, die auch nach
        dem Schließen des Browsers gespeichert bleiben. So kann z.B. der
        Login-Status gespeichert werden, wenn die Nutzer diese nach mehreren
        Tagen aufsuchen. Ebenso können in einem solchen Cookie die Interessen
        der Nutzer gespeichert werden, die für Reichweitenmessung oder
        Marketingzwecke verwendet werden. Als „Third-Party-Cookie“ werden
        Cookies bezeichnet, die von anderen Anbietern als dem Verantwortlichen,
        der das Onlineangebot betreibt, angeboten werden (andernfalls, wenn es
        nur dessen Cookies sind spricht man von „First-Party Cookies“).
      </p>
      <p>
        Wir können temporäre und permanente Cookies einsetzen und klären
        hierüber im Rahmen unserer Datenschutzerklärung auf.
      </p>
      <p>
        Falls die Nutzer nicht möchten, dass Cookies auf ihrem Rechner
        gespeichert werden, werden sie gebeten die entsprechende Option in den
        Systemeinstellungen ihres Browsers zu deaktivieren. Gespeicherte Cookies
        können in den Systemeinstellungen des Browsers gelöscht werden. Der
        Ausschluss von Cookies kann zu Funktionseinschränkungen dieses
        Onlineangebotes führen.
      </p>
      <p>
        Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des
        Onlinemarketing eingesetzten Cookies kann bei einer Vielzahl der
        Dienste, vor allem im Fall des Trackings, über die US-amerikanische
        Seite{' '}
        <a href="http://www.aboutads.info/choices/">
          http://www.aboutads.info/choices/
        </a>{' '}
        oder die EU-Seite{' '}
        <a href="http://www.youronlinechoices.com/">
          http://www.youronlinechoices.com/
        </a>{' '}
        erklärt werden. Des Weiteren kann die Speicherung von Cookies mittels
        deren Abschaltung in den Einstellungen des Browsers erreicht werden.
        Bitte beachten Sie, dass dann gegebenenfalls nicht alle Funktionen
        dieses Onlineangebotes genutzt werden können.
      </p>
      <p>
        <strong>Löschung von Daten</strong>
      </p>
      <p>
        Die von uns verarbeiteten Daten werden nach Maßgabe der Art. 17 und 18
        DSGVO gelöscht oder in ihrer Verarbeitung eingeschränkt. Sofern nicht im
        Rahmen dieser Datenschutzerklärung ausdrücklich angegeben, werden die
        bei uns gespeicherten Daten gelöscht, sobald sie für ihre
        Zweckbestimmung nicht mehr erforderlich sind und der Löschung keine
        gesetzlichen Aufbewahrungspflichten entgegenstehen. Sofern die Daten
        nicht gelöscht werden, weil sie für andere und gesetzlich zulässige
        Zwecke erforderlich sind, wird deren Verarbeitung eingeschränkt. D.h.
        die Daten werden gesperrt und nicht für andere Zwecke verarbeitet. Das
        gilt z.B. für Daten, die aus handels- oder steuerrechtlichen Gründen
        aufbewahrt werden müssen.
      </p>
      <p>
        Nach gesetzlichen Vorgaben in Deutschland erfolgt die Aufbewahrung
        insbesondere für 6 Jahre gemäß § 257 Abs. 1 HGB (Handelsbücher,
        Inventare, Eröffnungsbilanzen, Jahresabschlüsse, Handelsbriefe,
        Buchungsbelege, etc.) sowie für 10 Jahre gemäß § 147 Abs. 1 AO (Bücher,
        Aufzeichnungen, Lageberichte, Buchungsbelege, Handels- und
        Geschäftsbriefe, Für Besteuerung relevante Unterlagen, etc.).
      </p>
      <p>
        Nach gesetzlichen Vorgaben in Österreich erfolgt die Aufbewahrung
        insbesondere für 7 J gemäß § 132 Abs. 1 BAO (Buchhaltungsunterlagen,
        Belege/Rechnungen, Konten, Belege, Geschäftspapiere, Aufstellung der
        Einnahmen und Ausgaben, etc.), für 22 Jahre im Zusammenhang mit
        Grundstücken und für 10 Jahre bei Unterlagen im Zusammenhang mit
        elektronisch erbrachten Leistungen, Telekommunikations-, Rundfunk- und
        Fernsehleistungen, die an Nichtunternehmer in EU-Mitgliedstaaten
        erbracht werden und für die der Mini-One-Stop-Shop (MOSS) in Anspruch
        genommen wird.
      </p>
      <p>
        <strong>Erhebung von Zugriffsdaten und Logfiles</strong>
      </p>
      <p>
        Wir, bzw. unser Hostinganbieter, erhebt auf Grundlage unserer
        berechtigten Interessen im Sinne des Art. 6 Abs. 1 lit. f. DSGVO Daten
        über jeden Zugriff auf den Server, auf dem sich dieser Dienst befindet
        (sogenannte Serverlogfiles). Zu den Zugriffsdaten gehören Name der
        abgerufenen Webseite, Datei, Datum und Uhrzeit des Abrufs, übertragene
        Datenmenge, Meldung über erfolgreichen Abruf, Browsertyp nebst Version,
        das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite),
        IP-Adresse und der anfragende Provider.
      </p>
      <p>
        Logfile-Informationen werden aus Sicherheitsgründen (z.B. zur Aufklärung
        von Missbrauchs- oder Betrugshandlungen) für die Dauer von maximal 7
        Tagen gespeichert und danach gelöscht. Daten, deren weitere Aufbewahrung
        zu Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des
        jeweiligen Vorfalls von der Löschung ausgenommen.
      </p>
      <p>
        <strong>Amazon-Partnerprogramm</strong>
      </p>
      <p>
        Wir sind auf Grundlage unserer berechtigten Interessen (d.h. Interesse
        am wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6
        Abs. 1 lit. f. DSGVO) Teilnehmer des Partnerprogramms von Amazon EU, das
        zur Bereitstellung eines Mediums für Websites konzipiert wurde, mittels
        dessen durch die Platzierung von Werbeanzeigen und Links zu Amazon.de
        Werbekostenerstattung verdient werden kann (sog. Affiliate-System).
        Amazon setzt Cookies ein, um die Herkunft der Bestellungen
        nachvollziehen zu können. Unter anderem kann Amazon erkennen, dass Sie
        den Partnerlink auf dieser Website geklickt und anschließend ein Produkt
        bei Amazon erworben haben.
      </p>
      <p>
        Weitere Informationen zur Datennutzung durch Amazon und
        Widerspruchsmöglichkeiten erhalten Sie in der Datenschutzerklärung des
        Unternehmens:{' '}
        <a href="http://www.amazon.de/gp/help/customer/display.html/ref=footer_privacy?ie=UTF8&amp;nodeId=3312401">
          http://www.amazon.de/gp/help/customer/display.html/ref=footer_privacy?ie=UTF8&amp;nodeId=3312401
        </a>
        .
      </p>
      <p>
        <strong>Kontaktaufnahme</strong>
      </p>
      <p>
        Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail,
        Telefon oder via sozialer Medien) werden die Angaben des Nutzers zur
        Bearbeitung der Kontaktanfrage und deren Abwicklung gem. Art. 6 Abs. 1
        lit. b) DSGVO verarbeitet. Die Angaben der Nutzer können in einem
        Customer-Relationship-Management System (&#8220;CRM System&#8221;) oder
        vergleichbarer Anfragenorganisation gespeichert werden.
      </p>
      <p>
        Wir löschen die Anfragen, sofern diese nicht mehr erforderlich sind. Wir
        überprüfen die Erforderlichkeit alle zwei Jahre; Ferner gelten die
        gesetzlichen Archivierungspflichten.
      </p>
      <p>
        <strong>Kommentare und Beiträge</strong>
      </p>
      <p>
        Wenn Nutzer Kommentare oder sonstige Beiträge hinterlassen, werden ihre
        IP-Adressen auf Grundlage unserer berechtigten Interessen im Sinne des
        Art. 6 Abs. 1 lit. f. DSGVO für 7 Tage gespeichert. Das erfolgt zu
        unserer Sicherheit, falls jemand in Kommentaren und Beiträgen
        widerrechtliche Inhalte hinterlässt (Beleidigungen, verbotene politische
        Propaganda, etc.). In diesem Fall können wir selbst für den Kommentar
        oder Beitrag belangt werden und sind daher an der Identität des
        Verfassers interessiert.
        <br />
      </p>
      <p>
        <strong>Onlinepräsenzen in sozialen Medien</strong>
      </p>
      <p>
        Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und
        Plattformen, um mit den dort aktiven Kunden, Interessenten und Nutzern
        kommunizieren und sie dort über unsere Leistungen informieren zu können.
        Beim Aufruf der jeweiligen Netzwerke und Plattformen gelten die
        Geschäftsbedingungen und die Datenverarbeitungsrichtlinien deren
        jeweiligen Betreiber.
      </p>
      <p>
        Soweit nicht anders im Rahmen unserer Datenschutzerklärung angegeben,
        verarbeiten wir die Daten der Nutzer sofern diese mit uns innerhalb der
        sozialen Netzwerke und Plattformen kommunizieren, z.B. Beiträge auf
        unseren Onlinepräsenzen verfassen oder uns Nachrichten zusenden.
      </p>
      <p>
        <strong>Einbindung von Diensten und Inhalten Dritter</strong>
      </p>
      <p>
        Wir setzen innerhalb unseres Onlineangebotes auf Grundlage unserer
        berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und
        wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6
        Abs. 1 lit. f. DSGVO) Inhalts- oder Serviceangebote von Drittanbietern
        ein, um deren Inhalte und Services, wie z.B. Videos oder Schriftarten
        einzubinden (nachfolgend einheitlich bezeichnet als “Inhalte”).
      </p>
      <p>
        Dies setzt immer voraus, dass die Drittanbieter dieser Inhalte, die
        IP-Adresse der Nutzer wahrnehmen, da sie ohne die IP-Adresse die Inhalte
        nicht an deren Browser senden könnten. Die IP-Adresse ist damit für die
        Darstellung dieser Inhalte erforderlich. Wir bemühen uns nur solche
        Inhalte zu verwenden, deren jeweilige Anbieter die IP-Adresse lediglich
        zur Auslieferung der Inhalte verwenden. Drittanbieter können ferner so
        genannte Pixel-Tags (unsichtbare Grafiken, auch als &#8220;Web
        Beacons&#8221; bezeichnet) für statistische oder Marketingzwecke
        verwenden. Durch die &#8220;Pixel-Tags&#8221; können Informationen, wie
        der Besucherverkehr auf den Seiten dieser Website ausgewertet werden.
        Die pseudonymen Informationen können ferner in Cookies auf dem Gerät der
        Nutzer gespeichert werden und unter anderem technische Informationen zum
        Browser und Betriebssystem, verweisende Webseiten, Besuchszeit sowie
        weitere Angaben zur Nutzung unseres Onlineangebotes enthalten, als auch
        mit solchen Informationen aus anderen Quellen verbunden werden.
      </p>
      <p>
        <strong>Vimeo</strong>
      </p>
      <p>
        Wir binden die Videos der Plattform “Vimeo” des Anbieters Vimeo Inc.,
        Attention: Legal Department, 555 West 18th Street New York, New York
        10011, USA, ein. Datenschutzerklärung:{' '}
        <a href="https://vimeo.com/privacy">https://vimeo.com/privacy</a>.{' '}
      </p>
      <p>
        <strong>Youtube</strong>
      </p>
      <p>
        Wir binden die Videos der Plattform “YouTube” des Anbieters Google LLC,
        1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, ein.
        Datenschutzerklärung:{' '}
        <a href="https://www.google.com/policies/privacy/">
          https://www.google.com/policies/privacy/
        </a>
        , Opt-Out:{' '}
        <a href="https://adssettings.google.com/authenticated">
          https://adssettings.google.com/authenticated
        </a>
        .
      </p>
      <p>
        <strong>Google Fonts</strong>
      </p>
      <p>
        Wir binden die Schriftarten (&#8220;Google Fonts&#8221;) des Anbieters
        Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA,
        ein. Datenschutzerklärung:{' '}
        <a href="https://www.google.com/policies/privacy/">
          https://www.google.com/policies/privacy/
        </a>
        , Opt-Out:{' '}
        <a href="https://adssettings.google.com/authenticated">
          https://adssettings.google.com/authenticated
        </a>
        .
      </p>
      <p>
        <strong>Twitter</strong>
      </p>
      <p>
        Innerhalb unseres Onlineangebotes können Funktionen und Inhalte des
        Dienstes Twitter eingebunden, angeboten durch die Twitter Inc., 1355
        Market Street, Suite 900, San Francisco, CA 94103, USA. Hierzu können
        z.B. Inhalte wie Bilder, Videos oder Texte und Schaltflächen gehören,
        mit denen Nutzer Ihr Gefallen betreffend die Inhalte kundtun, den
        Verfassern der Inhalte oder unsere Beiträge abonnieren können. Sofern
        die Nutzer Mitglieder der Plattform Twitter sind, kann Twitter den
        Aufruf der o.g. Inhalte und Funktionen den dortigen Profilen der Nutzer
        zuordnen. Twitter ist unter dem Privacy-Shield-Abkommen zertifiziert und
        bietet hierdurch eine Garantie, das europäische Datenschutzrecht
        einzuhalten (
        <a href="https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active">
          https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active
        </a>
        ). Datenschutzerklärung:{' '}
        <a href="https://twitter.com/de/privacy">
          https://twitter.com/de/privacy
        </a>
        , Opt-Out:{' '}
        <a href="https://twitter.com/personalization">
          https://twitter.com/personalization
        </a>
        .
      </p>
      <p>
        <a class="dsg1-5" href="https://datenschutz-generator.de">
          Erstellt mit Datenschutz-Generator.de von RA Dr. Thomas Schwenke
        </a>
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
